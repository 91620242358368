import React from 'react';
import styled from 'styled-components';
import {
  Divider,
  UnorderedListStyles,
  BaseButton,
  BaseLink
} from 'aether-marketing';
import PropTypes from 'prop-types';
import LottiAnimationContainer from './LottiAnimationContainer';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const Section = styled.section`
  display: none;
  padding: 60px 0px;
  margin: auto;
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  .fullwrap {
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: 4px solid ${(props) => props.theme.colors.grey_30};
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
  }
  .gradientText {
    background-image: linear-gradient(to right, #ff6c37 0%, #c64dda 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h2.gradientText {
    font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial,
      sans-serif !important;
    letter-spacing: 0;
  }

  .svg-container {
    img {
      width: 90%;
    }

    @media (max-width: 992px) {
      margin-top: 24px;
    }
  }
`;

function PreFooterSideBySideLottie({
  layout,
  title,
  body,
  link,
  divider,
  media,
  button,
  backgroundColor,
  logo
}) {
  // Determine column widths
  const layoutString = layout.toUpperCase();
  let colText;
  let colMedia;
  switch (layoutString) {
    case '1R':
      colMedia = 'col-lg-6 offset-lg-0 mb-5 mb-lg-0';
      colText = 'col-lg-5 offset-lg-1 col-md-8 offset-md-2';
      break;
    case '1L':
      colText = 'col-12 col-lg-7 ';
      colMedia = 'col-12 col-lg-4 offset-lg-1';
      break;
    case '1E':
      colText = 'col-12 col-md-6 col-lg-6';
      colMedia = 'col-12 col-md-6 col-lg-5 offset-lg-1';
      break;
    default:
      colText = 'col-12 col-lg-5 offset-lg-0 align-items-center';
      colMedia = 'col-12 col-lg-6 offset-lg-1';
  }
  const textSection = (
    <div className={`${colText} `}>
      {logo && (
        <div className="col-lg-12 order-1 order-lg-1 pl-0">
          <img className="img-fluid" alt={logo.alt} src={logo.src} />
        </div>
      )}
      {title && <h2>{title}</h2>}
      {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
      {link && (
        <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
          <BaseLink
            target={link.target}
            src={link.url}
            linkType="arrowLink"
            as="a"
          >
            {link.text}
          </BaseLink>
        </div>
      )}
      {button && button.src && (
        <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
          <BaseButton
            buttonType={button.buttonType}
            as="a"
            src={button.src}
            target={button.target}
            id={button.id}
            gaCategory={button.gaCategory}
            gaLabel={button.gaLabel}
            className="mt-2"
          >
            {button.text}
          </BaseButton>
        </div>
      )}
    </div>
  );

  const mediaSection = (
    <div
      className={`${colMedia} ${media.border} ${
        media.isLottie ? 'mt-5 mt-lg-0' : 'svg-container'
      }`}
    >
      {media.isLottie ? (
        <LottiAnimationContainer
          autoplay={media.autoplay}
          loop={media.loop}
          animationFileName={media.lottieFileName}
        />
      ) : (
        <img alt={media.alt} src={media.src} />
      )}
    </div>
  );

  return (
    <>
      <Section style={{ backgroundColor }}>
        <div className="container ">
          {layoutString === '1R' && (
            <div className="row align-items-center">
              {mediaSection}
              {textSection}
            </div>
          )}
          {layoutString === '1L' ||
            (layoutString === '1E' && (
              <div className="row align-items-center">
                {textSection}
                {mediaSection}
              </div>
            ))}
        </div>
      </Section>
      {divider && <Divider />}
    </>
  );
}

export default PreFooterSideBySideLottie;
PreFooterSideBySideLottie.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string
  }),
  body: PropTypes.arrayOf(PropTypes.string),
  backgroundColor: PropTypes.string,
  divider: PropTypes.bool,
  logo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    class: PropTypes.string,
    border: PropTypes.string,
    isLottie: PropTypes.bool,
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
    lottieFileName: PropTypes.string
  }),
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    class: PropTypes.string,
    border: PropTypes.string,
    isLottie: PropTypes.bool,
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
    lottieFileName: PropTypes.string
  }),
  button: PropTypes.shape({
    buttonType: PropTypes.string,
    text: PropTypes.string,
    src: PropTypes.string,
    target: PropTypes.string,
    id: PropTypes.string,
    gaCategory: PropTypes.string,
    gaLabel: PropTypes.string,
    icon: PropTypes.string,
    triggerGA: PropTypes.func,
    rel: PropTypes.string,
    test: PropTypes.string,
    event: PropTypes.string,
    eventProp: PropTypes.string,
    ariaLabel: PropTypes.string,
    category: PropTypes.string,
    label: PropTypes.string
  })
};

PreFooterSideBySideLottie.defaultProps = {
  layout: '1E',
  title: null,
  body: null,
  link: {
    url: null,
    text: null,
    target: null
  },
  backgroundColor: '#FFFFFF',
  divider: false,
  button: {
    id: null,
    gaCategory: null,
    gaLabel: null,
    icon: null
  },
  media: null,
  logo: null
};
